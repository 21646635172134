import styled from 'styled-components'

import HolidayUniIcon from './HolidayUniIcon'

// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
export type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string
  height?: string | number
  width?: string | number
  gradientId?: string
}

export const UniIcon = (props: SVGProps) => (
  <Container>
    {HolidayUniIcon(props) !== null ? (
      <HolidayUniIcon {...props} />
    ) : (
      <svg {...props} xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M37,24.5c0.2-3,0.9-5.9,1.8-8.8c0.8-2.4,1.1-4.8,0.2-7.3c-0.3-0.8-0.6-1.6-0.5-2.5c0-0.6-0.2-1-0.8-1.2
	c-0.5-0.1-0.9,0.1-1.1,0.5c-0.3,0.5-0.1,1,0.3,1.4c1.3,1.4,1.5,3,0.7,4.7c-1.1,2.2-3.3,2.5-4.8,0.5c-0.8-1-1.6-2-2.5-2.9
	c-0.7-0.7-1.5-1.3-1.8-2.3c-0.2-0.6-0.7-1-1.3-0.8c-0.5,0.1-0.8,0.4-0.8,0.9c0,0.7,0.4,1.2,1,1.3c1.6,0.2,2.1,1.4,2.7,2.6
	c3.8,7.9,0.3,17.2-7.6,20.1c-3.7,1.3-7.2,0.8-10.6-0.8c-2.1-1.5-4.1-3.1-5.4-5.4C4,20.8,4.3,16.5,7.1,12.9c2.5-3.2,6.2-4.4,10.2-3.3
	c4.6,1.3,7.3,5.9,5.8,10.1c-0.8,2.4-2.3,4.2-5,4.6c-2.7,0.4-4.9-0.6-6.5-2.9c-1.4-2-1.1-4.7,0.5-6.2c1.5-1.4,3.7-1.6,5.4-0.4
	c0.6,0.4,1.2,1,0.7,1.8c-0.5,0.9-1.3,0.8-2,0.3c-0.8-0.5-1.5-0.6-2.3,0c-0.8,0.6-0.9,1.6-0.6,2.5c0.8,2.6,4,3.4,6,1.6
	c1.7-1.5,2.1-4.3,1.1-6.4c-1.7-3.5-6.5-4.4-9.8-1.9C6.6,16,6.2,21.6,9.9,25.4c4.5,4.5,10.5,4.9,14.6,0.9c5.4-5.3,4.4-14.3-2-18.4
	C16.5,4,9,4.6,4.7,9.4c0,0,0,0,0,0c-4.3,4.1-5.1,11.3-1.7,16.9c0.8,1.3,1.7,2.7,2.2,4.1c0.8,2,0.2,3.4-1.8,4.4
	c-0.5,0.2-0.9,0.4-1.5,0.7c12.6,0,25,0,37.6,0C36.9,32.1,36.8,28.3,37,24.5z"
        />
      </svg>
    )}
  </Container>
)

const Container = styled.div`
  position: relative;
`
