import { ButtonEmpty } from "components/Button";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";
import { useIsDarkMode } from "theme/components/ThemeToggle";

import meshSrc from "./images/Mesh.png";

const DARK_MODE_GRADIENT =
  "linear-gradient(139.57deg, #1b1b1b 4.35%, #904ef8 96.44%)";

const Banner = styled.div<{ isDarkMode: boolean }>`
  
  width: 100%;
  border-radius: 32px;
  max-width: 1440px;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px;

  box-shadow: 0px 10px 24px rgba(51, 53, 72, 0.04);

  background: ${({ isDarkMode }) =>
    isDarkMode
      ? `url(${meshSrc}), ${DARK_MODE_GRADIENT}`
      : `url(${meshSrc}), linear-gradient(139.57deg, #1b1b1b 4.35%, #904ef8 96.44%);`};
      @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
        height: 300px;
        flex-direction: row;
      }
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    height: 300px;
    flex-direction: row;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    height: 300px;
    flex-direction: row;
  }
`;

const TextContainer = styled.div`
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const HeaderText = styled.div`
  font-weight: 535;
  font-size: 28px;
  line-height: 36px;

  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const DescriptionText = styled.div`
  margin: 10px 10px 0 0;
  font-weight: 535;
  font-size: 13px;
  line-height: 20px;
  text-align: justify;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    font-size: 13px;
    line-height: 25px;
  }
`;

const BannerButtonContainer = styled.div`
  width: 100%;
  display: flex;
  transition: ${({ theme }) =>
    `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    width: auto;
  }
`;

const BannerButton = styled.div`
  word-break: break-all;
  line-height: 30px;
  font-size: 13px;
  width: 350px;
  font-weight: bold;
  color: white;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    font-size: 13px;
    line-height: 25px;
  }
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
  }
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    width:350px;
  }
`;

const AboutToken = () => {
  const isDarkMode = useIsDarkMode();
  return (
    <Banner isDarkMode={isDarkMode}>
      <TextContainer>
        <HeaderText>About SNAT Token</HeaderText>
        <DescriptionText>
          $SNAT is the official utility token of the SnailSat ecosystem,
          providing a range of engaging services within the BRC20 network and
          the Ethereum Virtual Machine (EVM) environment. Built with the goal of
          optimizing user experience and deeply integrating into the SnailSat
          ecosystem, $SNAT serves not only as a means of payment but also as a
          dynamic force driving interaction within the community.
        </DescriptionText>
      </TextContainer>
      <BannerButtonContainer>
        <BannerButton>
          <ul>
            <li>Coin: $SNAT</li>
            <li>Total Supply: 10B</li>
            <li>
              {" "}
              Ordinals BRC:
              d214fd985a4125411a455a782bb7523c95eb5a3e306b923d84c77a31022572fei0
            </li>
            <li> ETH: 0xd19044F8b0E614bf3b20D08122279eB7AA376E7D</li>
          </ul>
        </BannerButton>
      </BannerButtonContainer>
    </Banner>
  );
};

export default AboutToken;
